<template>
    <div id="kt_account_signin_method" class="collapse show">
      <div class="card-body border-top p-9">
        <div class="d-flex flex-wrap align-items-center mb-8">
          <div id="kt_signin_email" :class="{ 'd-none': emailFormDisplay }">
            <div class="fs-4 fw-boldest mb-1">{{ $t('Email Address') }}</div>
            <div class="fs-6 fw-bold text-gray-600">{{signInMethod.email}}</div>
          </div>

          <div
            id="kt_signin_email_edit"
            :class="{ 'd-none': !emailFormDisplay }"
            class="flex-row-fluid"
          >
          </div>
          <div
            id="kt_signin_email_button"
            :class="{ 'd-none': emailFormDisplay }"
            class="ms-auto"
          >
            <button
              @click="dialogChangeEmail = true"
              class="btn text-primary bg-transparent fw-boldest"
            >
              {{ $t('Change Email') }}
            </button>
            <div class="addQuestion">
            </div>
            <div v-if="dialogChangeEmail">
              <ChangeEmailModal
                :popupVisible="dialogChangeEmail"
                :platformType="signInMethod.is_bank_user ? PlatformTypeEnum.BANK : PlatformTypeEnum.BUSINESS"
                @handle-close="dialogChangeEmail = false"
              />
            </div>
          </div>
        </div>

        <div class="d-flex flex-wrap align-items-center mb-8">
          <div
            id="kt_signin_password"
            :class="{ 'd-none': passwordFormDisplay }"
          >
            <div class="fs-4 fw-boldest mb-1">{{ $t('Password') }}</div>
            <div class="fs-6 fw-bold text-gray-600">************</div>
          </div>
          <div
            id="kt_signin_password_edit"
            class="flex-row-fluid"
            :class="{ 'd-none': !passwordFormDisplay }"
          >
            <div class="fs-6 fw-bold text-gray-600 mb-4">
              {{ $t('Password must be at least 8 character and contain symbols') }}
            </div>
          </div>
          <div
            id="kt_signin_password_button"
            class="ms-auto"
            :class="{ 'd-none': passwordFormDisplay }"
          >
            <button
              @click="dialogChangePassword = true"
              class="btn text-primary bg-transparent fw-boldest"
            >
              {{ $t('Change Password') }}
            </button>
            <div v-if="dialogChangePassword">
              <ChangePasswordModal
                :popupVisible="dialogChangePassword"
                @handle-close="dialogChangePassword = false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
 import ChangeEmailModal from "@/buying-teams/shared-components/modals/ChangeEmailModal";
 import ChangePasswordModal from "@/buying-teams/shared-components/modals/ChangePasswordModal";
 import {PlatformTypeEnum} from "@/store/enums/PlatformTypeEnum";

 export default {
   name: 'SignInMethod',
   components: { ChangePasswordModal, ChangeEmailModal },
   props: [
     'signInMethod'
   ],
   data(){
     return{
       PlatformTypeEnum,
       delivery: '',
       emailFormDisplay: false,
       dialogChangeEmail: false,
       dialogChangePassword: false,
       passwordFormDisplay: false,
       changeEmail: {},
     }
   }
 }
</script>
