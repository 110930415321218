
import { ErrorMessage, Field, Form } from "vee-validate";
import {useStore} from "vuex";
import {defineComponent, ref} from "vue";
import * as Yup from "yup";
import {Actions} from "@/store/enums/StoreEnums";
import {useI18n} from "vue-i18n";

export default defineComponent ({
  name: "ChangePasswordModal",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  props: {
    popupVisible: Boolean
  },

  emits: ["handle-close"],

  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n()
    const confirmPassword = "";
    const password = "";
    const showCurrentPassword = ref(false);
    const showNewPassword = ref(false);
    const showConfirmNewPassword = ref(false);

    const closePopup = () => {
      emit("handle-close");
    }

    const submitButton = ref<HTMLButtonElement | null>(null);

    const createPassword = Yup.object().shape({
      password: Yup.string()
          .required(t('Password is a required field')),
      oldPassword: Yup.string()
          .required(t('Password is a required field')),
      confirmPassword: Yup.string()
          .required(t('Confirm Password is a required field'))
          .oneOf([Yup.ref('password'), null], t('Passwords must match'))
    });

    const onSubmitCreatePassword = async (values) => {
      if (submitButton.value) {
        submitButton.value!.disabled = true;
        submitButton.value?.setAttribute("data-kt-indicator", "on");
      }

      store.dispatch(Actions.CHANGE_PASSWORD, {
        currentPassword: values.oldPassword,
        newPassword: values.password
      })
        .then((res) => {
          if (res.status === 200) {
            // TODO Add Notification
            closePopup()
          }
        })
        .finally(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
          submitButton.value!.disabled = false;
        });
    }

    return {
      closePopup,
      onSubmitCreatePassword,
      createPassword,
      submitButton,
      password,
      confirmPassword,
      showCurrentPassword,
      showNewPassword,
      showConfirmNewPassword
    }
  }
});
