<template>
  <VOtpInput
    :class="contentCustomClass ? contentCustomClass : 'custom-otp-inputs'"
    :input-classes="inputCustomClass ? inputCustomClass : 'otp-input'"
    :ref="ref ? ref : 'otpInputRef'"
    separator="-"
    :num-inputs="length ? length : 6"
    :should-auto-focus="true"
    :is-input-num="true"
    @on-change="handleOtpInputsChange"
    @on-complete="handleOtpInputsComplete"
  />
</template>

<script>
import VOtpInput from 'vue3-otp-input';

export default {
  name: "OtpInputComponent",
  components: {VOtpInput},
  props: {
    length: Number,
    ref: String,
    contentCustomClass: String,
    inputCustomClass: String
  },
  emits: ['handleOtpInputsChange', 'handleOtpInputsComplete'],
  methods: {
    handleOtpInputsChange(otpValue) {
      this.$emit('handleOtpInputsChange', otpValue);
    },
    handleOtpInputsComplete(code) {
      this.$emit('handleOtpInputsComplete', code);
    },
    handleClearInput(ref) {
      this.$refs[ref].clearInput();
    }
  }
}
</script>

<style scoped>

</style>

<!--handle clear inputs-->
<!--(ref).clearInput()-->

<!--<OtpInputComponent-->
<!--    :contentCustomClass="'custom-otp-inputs'"-->
<!--    :inputCustomClass="'otp-input'"-->
<!--    :length="6"-->
<!--    @handleOtpInputsComplete="handleOtpInputsComplete"-->
<!--/>-->