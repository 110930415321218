<template>
  <el-dialog
    v-model="popupVisible"
    width="753px"
    center
    :show-close="true"
    custom-class="changingEmail-modal"
    @close="closePopup"
  >
    <div class="changingEmail-modal__content" v-if="step === 1">
      <h2>{{$t('Changing Email Address')}}</h2>
      <p class="mb-15">{{$t('Enter your new Email Address. We recommend using work mails.')}}</p>
      <el-form
        label-width="150px"
        ref="changingEmail"
        :model="changingEmail"
        :rules="changingEmailRules"
      >
        <el-form-item
          prop="email"
          :label="$t('New Email Address')">
          <el-input
            v-model="changingEmail.email"
            :placeholder="$t('Enter New Email Address')"
          />
        </el-form-item>
        <el-form-item
            prop="confirmEmail"
            :label="$t('Confirm new email')">
          <el-input
            v-model="changingEmail.confirmEmail"
            :placeholder="$t('Re-enter new email address to confirm (new@mail.now)')"
          />
        </el-form-item>
        <hr>
        <el-form-item
          prop="password"
          class="grey-field mt-6"
          :label="$t('Confirm  Password')">
          <el-input
            show-password
            v-model="changingEmail.password"
            type="password"
            :placeholder="$t('Enter your current Password')"
          />
        </el-form-item>
        <hr>

        <button
          :data-kt-indicator="buttonIndicator"
          :disabled="buttonIndicator === 'on'"
          class="main-btn d-block mx-auto btn w-50 mt-10"
          @click.prevent="confirmPopup"
        >
          <span class="indicator-label"> {{ $t('Verify new email') }}</span>

          <span class="indicator-progress">
          {{ $t("pleaseWait") }}
          <span
              class="spinner-border spinner-border-sm align-middle ms-2"
          ></span>
          </span>
        </button>
      </el-form>
    </div>

    <div class="changingEmail-modal__content" v-if="step === 2">
      <h2>{{$t('Verify to change email')}}</h2>
      <p class="mb-8">
        {{ $t('We have sent an email to your new email address i.e', { email: getHidedEmail(this.changingEmail.email) }) }}<br />
        {{ $t('Please type the verification code here:') }}
      </p>

      <el-form
        ref="updateEmailForm"
        class="update-email-form"
        :class="{'is-form-disabled': resendCodeLoading}"
        :model="updateEmailForm"
        :rules="updateEmailFormRules"
      >
        <el-form-item prop="verificationCode">
          <div class="verification-block">
            <OtpInputComponent
              ref="verificationCode"
              :contentCustomClass="'custom-otp-inputs'"
              :inputCustomClass="'otp-input'"
              :length="6"
              @handleOtpInputsChange="updateEmailForm.verificationCode = $event"
              @handleOtpInputsComplete="updateEmailForm.verificationCode = $event"
            />
          </div>
        </el-form-item>
      </el-form>

      <div class="received-section">
        <p>
          {{ $t('Not Received?') }}
          <el-button @click.prevent="resendCode" :loading="resendCodeLoading">
            {{ $t('Resend Code') }}
          </el-button>
        </p>
      </div>

      <button
        :data-kt-indicator="buttonIndicator"
        :disabled="buttonIndicator === 'on' || resendCodeLoading"
        class="main-btn d-block mx-auto btn w-50 mt-10"
        @click.prevent="updateEmail"
      >
        <span class="indicator-label"> {{ $t('Update Email') }}</span>

        <span class="indicator-progress">
          {{ $t("pleaseWait") }}
          <span
              class="spinner-border spinner-border-sm align-middle ms-2"
          ></span>
          </span>
      </button>
    </div>
  </el-dialog>
</template>
<script>

import store from "@/store";
import NotificationService from "@/buying-teams/services/NotificationService";
import {DataService} from "@/core/services/DataService";
import {PlatformTypeEnum} from "@/store/enums/PlatformTypeEnum";
import {getHidedEmail} from "@/core/helpers/GlobalHelper";
import OtpInputComponent from "@/buying-teams/pages/authentication/verification-code/OtpInputComponent";

export default {
  name: "ChangeEmailModal",
  components: {OtpInputComponent},
  props: ["popupVisible", "platformType"],

  emits: ["handle-close", "handle-confirm"],

  computed: {
    currentUser() {
      return store.getters.currentUser;
    }
  },

  data() {
    const validateEmailConfirm = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('Please input Email')))
      } else if (value !== this.changingEmail.email) {
        callback(new Error(this.$t('Email doesn\'t match!')))
      } else {
        callback()
      }
    }
    const validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('Please input Code')))
      } else if (value.length !== 6) {
        callback(new Error(this.$t('Code is incorrect')))
      } else {
        callback()
      }
    }
    return {
      step: 1,
      getHidedEmail,
      dialogFormVisible: false,
      buttonIndicator: "off",
      changingEmail: {
        email: "",
        confirmEmail: "",
        password: "",
      },
      changingEmailRules: {
        email: [
          {
            required: true,
            message: this.$t("Please input Email"),
            trigger: "change"
          },
          {
            required: true,
            type: "email",
            message: this.$t("Please input correct email"),
            trigger: "change"
          }
        ],
        confirmEmail: { trigger: "change", validator: validateEmailConfirm },
        password: { required: true, message: this.$t("Please input Password"), trigger: "change" },
      },
      updateEmailForm: {
        verificationCode: "",
      },
      updateEmailFormRules: {
        verificationCode: { trigger: "change", validator: validateCode }
      },
      resendCodeLoading: false
    };
  },

  methods: {
    closePopup() {
      this.$emit("handle-close");
    },
    confirmPopup() {
      this.$refs.changingEmail.validate(async (valid) => {
        if (valid) {
          this.buttonIndicator = 'on';
          await store.dispatch("verifyUserNewEmail", {
            email: this.changingEmail.email,
            password: this.changingEmail.password
          }).then(() => {
            this.step = 2;
          }).finally(() => {
            this.buttonIndicator = "off";
          });
        }
      })
    },
    updateEmail() {
      this.$refs.updateEmailForm.validate( async (valid) => {
        if (valid) {
          this.buttonIndicator = 'on';
          await store.dispatch(
            this.platformType === PlatformTypeEnum.BANK ?
              "updateBankUserProfileDetails" :
              "updateProfileDetails",
            {
            email: this.changingEmail.email,
            verification_code: this.updateEmailForm.verificationCode,
            current_password: this.changingEmail.password
          }).then(() => {
            this.currentUser.selfUpdate({
              email: this.changingEmail.email
            });
            DataService.storeAuthUserData(this.currentUser);
            NotificationService.swalCustom({
              title: "Success",
              icon: "success"
            });
            this.$emit("handle-close");
          }).finally(() => {
            this.buttonIndicator = "off";
          });
        }
      })
    },
    async resendCode() {
      this.resendCodeLoading = true;
      this.updateEmailForm.verificationCode = '';
      this.$refs.verificationCode.$refs.otpInputRef.clearInput();
      await store.dispatch("verifyUserNewEmail", {
        email: this.changingEmail.email,
        password: this.changingEmail.password
      }).finally(() => {
        this.resendCodeLoading = false;
      });
    }
  }
};
</script>
<style lang="scss">
.changingEmail-modal {
  .el-dialog__header {
    padding: 0;
  }
  .el-input__inner {
    background: #F6F6F6;
    border-radius: 5px;
    border: none;
    box-shadow: none;
    &:focus {
      background: #F6F6F6;
    }
  }
  .el-form-item__label {
    width: 220px !important;
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    display: flex;
    align-items: center;
    &::before {
      display: none;
    }
  }
  .el-form-item.grey-field {
    .el-form-item__label {
      color: #8B8B8B;
    }
  }
  .verification-block {
    display: flex;
    justify-content: center;
    .custom-otp-inputs {
      gap: 15px;
      > div {
        width: auto;
      }
    }
    .custom-otp-inputs .otp-input {
      width: 44.9px !important;
      height: 54.14px !important;
      margin: 0;
    }
  }
  .received-section {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    margin-top: 26px;
    p {
      color: #000000;
    }
    .el-button {
      color: #2943EA;
      padding: 0 !important;
      min-height: auto;
      text-decoration: underline;
      &:hover {
        background: transparent;
      }
    }
  }
  &__content {
    > h2 {
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      color: #000000;
    }
    > p {
      font-weight: 400;
      font-size: 16px;
      line-height: 178.52%;
      color: #434343;
      opacity: 0.6;
    }
  }
  .update-email-form {
    .el-form-item__error {
      width: 100%;
      text-align: center;
      margin-top: 10px;
    }
    .el-form-item {
      margin-bottom: 40px;
    }
    &.is-form-disabled {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 9;
      }
    }
  }
}

@media (max-width: 860px) {
  .changingEmail-modal {
    --el-dialog-width: 98% !important;
  }
}

@media (max-width: 600px) {
  .changingEmail-modal {
    .el-form-item {
      flex-direction: column;
    }
    .el-form-item__label {
      margin-bottom: 15px;
      width: 100% !important;
    }
  }
}
</style>
